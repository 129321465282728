import React from "react";
import { useQuery, useMutation } from "@apollo/client/react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {
	Title,
	SimpleForm,
	required,
	NumberInput,
	minValue,
	maxValue,
	ArrayInput,
	SimpleFormIterator,
	TextInput,
	useNotify,
} from "react-admin";
import type { GetPricingModelQuery } from "~/api/get-pricing-model-query.generated.ts";
import getPricingModelQuery from "~/api/get-pricing-model-query.graphql";
import removeTypenames from "~/utils/remove-typenames.ts";
import type { UpdatePricingModelMutationVariables } from "~/api/update-pricing-model-mutation.ts";
import updatePricingModelMutation from "~/api/update-pricing-model-mutation.ts";

function EditPricingModel() {
	const notify = useNotify();

	const { data: pricingData } =
		useQuery<GetPricingModelQuery>(getPricingModelQuery);

	const [savePricingModel, savePricingModelResult] = useMutation<
		unknown,
		UpdatePricingModelMutationVariables
	>(updatePricingModelMutation, {
		onCompleted() {
			notify("Pricing model updated", "info");
		},
		onError(e) {
			notify(e.toString(), { type: "error" });
		},
	});

	const onSave = async (variables: any) => {
		await savePricingModel({
			variables,
		});
	};

	const pricingModel = (() => {
		if (!pricingData) {
			return undefined;
		}

		return {
			...pricingData.pricingModel,
			basePlateSizePrices: pricingData.pricingModel.basePlateSizePrices.map(
				(sizePrice) => ({
					size: sizePrice.size,
					priceDollars: parseFloat(sizePrice.price.amount),
				}),
			),
		};
	})();

	return (
		<Card>
			<Title title="Pricing Model" />
			<CardContent>
				{pricingModel ? (
					<SimpleForm
						initialValues={removeTypenames(pricingModel)}
						save={onSave}
						saving={savePricingModelResult.loading}
					>
						<NumberInput
							source="numColoursPremiumThreshold"
							validate={[required(), minValue(1)]}
							step={1}
							min={1}
							helperText="Designs with number of colours above this will incur an extra cost"
						/>
						<NumberInput
							source="numColoursPremium"
							helperText="as a ratio, e.g. 0.05 = 5%"
							validate={[required(), minValue(0), maxValue(1)]}
							step={0.01}
							min={0}
							max={1}
						/>
						<ArrayInput source="basePlateSizePrices">
							<SimpleFormIterator disableAdd disableRemove>
								<TextInput source="size" label="Baseplate size" disabled />
								<NumberInput
									source="priceDollars"
									label="Price"
									helperText="per plate"
									validate={[required(), minValue(0)]}
									step={0.01}
									min={0}
								/>
							</SimpleFormIterator>
						</ArrayInput>
						<ArrayInput source="discountBrackets">
							<SimpleFormIterator>
								<NumberInput
									source="areaMetres"
									label="Area"
									helperText="metres sq."
									validate={[required(), minValue(0)]}
									step={0.01}
									min={0}
								/>
								<NumberInput
									source="discount"
									label="Discount"
									helperText="as a ratio, e.g. 0.05 = 5%"
									validate={[required(), minValue(0), maxValue(1)]}
									step={0.01}
									min={0}
									max={1}
								/>
							</SimpleFormIterator>
						</ArrayInput>
					</SimpleForm>
				) : (
					<div>Loading</div>
				)}
			</CardContent>
		</Card>
	);
}

export { EditPricingModel };
